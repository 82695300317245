<template>
	<noAuth>
		<div class="broadcastManage">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label" style="display: inline-block;width:75px;text-align: right;">关键字: </label>
					<el-input v-model="searchKey" placeholder="直播间ID/直播标题" style="width: 250px;margin-right: 10px;" clearable></el-input>
				</div>
				<div class="filter-item" v-if="isAgentModel == 2">
					<label class="label">创建人: </label>
					<el-select v-model="LiveAnchorIdList" style="width: 150px;margin-right: 10px;" multiple filterable clearable>
						<el-option label="全部" :value="-1"></el-option>
						<el-option label="平台" :value="0"></el-option>
						<el-option v-for="item in LiveAnchorList" :key="item.Id" :label="item.Name" :value="item.Id">
						</el-option>
					</el-select>
				</div>
			</div>
		
			<div class="filter-container" style="margin-top: 15px;">
				<div class="filter-item">
					<label class="label">开播时间: </label>
					<el-date-picker type="date" placeholder="开始日期" :picker-options="pickerOptions" v-model="starTime" style="width: 250px;"
					 value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker> ~
					<el-date-picker type="date" placeholder="结束日期" :picker-options="pickerOptions" v-model="endTime" style="width: 250px;"
					 value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
				</div>
				<div class="filter-item">
					<label class="label">直播状态: </label>
					<el-select v-model="searchState" style="width: 150px;margin-right: 10px;" @change="keyChange" clearable>
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in searchStateList" :key="item.id" :label="item.value" :value="item.id">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<label class="label">分组: </label>
					<el-select v-model="groupIds" style="width: 250px;margin-right: 10px;" multiple @change="keyChange" clearable>
						<el-option label="全部" :value="-1"></el-option>
						<el-option label="未分组" :value="0"></el-option>
						<el-option v-for="item in livegrouplist" :key="item.Value" :label="item.Text" :value="item.Value">
						</el-option>
					</el-select>
				</div>
				<div class="filter-item">
					<el-button type="primary" @click="goodsFilter">查询</el-button>
					<el-button style="margin-left: 15px;" @click="getNewBroad">获取最新直播</el-button>
				</div>
			</div>
			<!-- 表格 -->
			<div class="table-container" style="margin-top:20px">
				<el-table :data="broadData" style="width: 100%;" @selection-change="handleSelectionChange" v-loading="loading" ref="multipleTable"
				 :row-key="rowKeys" @sort-change="sortChange">
					<el-table-column type="selection" width="55"></el-table-column>
					<el-table-column prop="RoomId" label="直播间ID"></el-table-column>
					<el-table-column prop="AnchorNickName" label="主播">
						<template slot-scope="scope">
							<div style="width:200px;">
								<pre style="white-space: pre-wrap;white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.AnchorNickName}}</pre>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="创建人">
						<template slot-scope="scope">
							<div>
								<span v-if="scope.row.IsPlatform&&scope.row.LiveAnchorName">
									<span>{{scope.row.LiveAnchorName}};</span><span style="color: red;">平台</span>
								</span>
								<span v-else-if='scope.row.LiveAnchorName'>{{scope.row.LiveAnchorName}}</span>
								<span v-else-if='scope.row.IsPlatform' style="color: red;">平台</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="AnchorImgUrl" label="直播标题" width="200">
						<template slot-scope="scope">
							<div class="product-info" style="align-items:flex-start">
								<img v-if="scope.row.AnchorImgUrl" :src="scope.row.AnchorImgUrl" style="width:100px;height:75px">
								<svg-icon v-else icon-class="noImgIcon" />
								<div style="min-height: auto;width:200px;">
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.RoomName }}</pre>
								</div>
							</div>
						</template>
		
					</el-table-column>
					<el-table-column prop="AddTime" label="直播时间" width="370">
						<template slot-scope="scope">
							<el-date-picker style="width:335px;" @input="changedata(scope.row)" :clearable="false" size="small" v-model="scope.row.StartEndtime"
							 value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至"
							 start-placeholder="开始日期" end-placeholder="结束日期">
							</el-date-picker>
						</template>
					</el-table-column>
					<el-table-column prop="LiveRoomGroupList" label="所属分组" width="160">
						<template slot-scope="scope">
							<div v-if='scope.row.LiveRoomGroupList.length' style="display: -webkit-box;overflow: hidden;text-overflow: ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
								<span v-for="(item,index) in scope.row.LiveRoomGroupList" :key="index">{{index == scope.row.LiveRoomGroupList.length-1 ? item:item + ','}}</span>
							</div>
							<div v-else>--</div>
						</template>
					</el-table-column>
					<el-table-column prop="StateValue" label="直播状态">
						<template slot-scope="scope">
							<div>{{scope.row.StateValue}}</div>
						</template>
					</el-table-column>
					<el-table-column label="序号" sortable>
						<template slot-scope="scope">
							<div>
								<span style="margin-right: 10px;">{{scope.row.SortDesc}}</span>
								<el-popover placement="right" width="230" v-model="scope.row.Numbervisible">
									<div>
										<div>序号值越大，排序越靠前</div>
										<div style="display: flex;margin-top:5px;">
											<el-input v-model="scope.row.SortDesc" style="width: 100px;margin-right: 10px;" @input='OnlyNumber1(scope.row.SortDesc,scope.$index)'></el-input>
											<el-button type='text' @click="SaveNumber(scope.row,scope.$index)">保存</el-button>
											<el-button type='text' @click="scope.row.Numbervisible = false;scope.row.SortDesc=0">取消</el-button>
										</div>
		
									</div>
		
									<i class="el-icon-edit" slot="reference"></i>
								</el-popover>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="操作" width="300">
						<template slot-scope="scope">
							<buttonPermissions :datas="'livemanagedivide'" style='margin-right: 10px;'>
								<el-button type="text" class="pointers" @click="sortsBatchs(scope.row)">分组</el-button>
							</buttonPermissions>
							<el-button type="text" class="pointers" @click="gettransData(scope.row)">交易转化</el-button>
							<el-button type="text" class="pointers" v-if="scope.row.StateValue=='已结束'" @click="showReplay(scope.row)">直播回放</el-button>
		
							<buttonPermissions :datas="'livemanagedelelte'">
								<span style="color:#f00;margin-left:15px;cursor: pointer;" v-if="scope.row.State!=1" @click="deleteChose(scope.row)">删除</span>
							</buttonPermissions>
		
							<el-button type="text" style="margin-right: 10px;margin-left: 10px;" @click='Editdefault(scope.row)'>
								<span v-if="scope.row.IsPlatform" style="color: #F56C6C;">取消平台标记</span>
								<span v-else style="color: #409EFF;">标记平台</span>
							</el-button>
		
						</template>
					</el-table-column>
				</el-table>
		
				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin:20px 0 0 13px;">
						<el-checkbox @change="tabCheckedChange" v-model="checked">当前页全选</el-checkbox>
						<buttonPermissions :datas="'livemanagedivide'" style='margin-left: 10px;'>
							<el-button size="mini" style="margin-left: 10px;" @click="sortsBatchs({})">批量设置分组</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'livemanagedelelte'">
							<el-button size="mini" style="margin-left: 10px;" @click="deleteChose({})">批量删除</el-button>
						</buttonPermissions>
		
					</div>
					<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		
			<!-- 修改序号弹框 -->
			<el-dialog title="首页序号" :visible.sync="headOrderVisible" width="500px" @close="CanClose">
				<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
					<el-form-item label="首页序号" prop="headSort">
						<input v-model="ruleForm.headSort" style="padding:0 10px;width:250px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 oninput="this.value=this.value.replace(/\D/g,'').replace(/^0+(?=\d)/,'')" type="number" />
						
						<div style="color:#888;font-size: 14px;">数值越大，直播间展示越靠前</div>
					</el-form-item>
		
					<div style="text-align: center;margin-top: 50px;">
						<el-button type="primary" @click="sureSends('ruleForm')" style="width:120px" :loading="saveLoading">保存</el-button>
					</div>
		
				</el-form>
		
			</el-dialog>
		
			<!-- 修改序号弹框 -->
			<el-dialog title="交易转化数据" :visible.sync="transferVisible" width="700px">
				<!-- <div style="margin-bottom: 15px;">交易转化</div> -->
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>交易转化</span>
					</div>
					<div class="transfercontent" style="display: flex;">
						<div class="item_content">
							<div>下单人数</div>
							<div class="num">{{transferData.SubmitOrderMemberCount}}</div>
						</div>
						<div class="item_content">
							<div>下单笔数</div>
							<div class="num">{{transferData.OrderCount}}</div>
						</div>
						<div class="item_content">
							<div>下单金额</div>
							<div class="num">{{transferData.SubmitOrderMoney}}</div>
						</div>
						<div class="item_content">
							<div>支付人数</div>
							<div class="num">{{transferData.PayOrderMemberCount}}</div>
						</div>
						<div class="item_content">
							<div>支付笔数</div>
							<div class="num">{{transferData.PayCount}}</div>
						</div>
						<div class="item_content">
							<div>支付金额</div>
							<div class="num">{{transferData.PayOrderMoney}}</div>
						</div>
						<div class="item_content">
							<div>推手实得自营收益</div>
							<div class="num">{{transferData.MemberCommissionAwardMoney}}</div>
						</div>
		
						<div class="item_content">
							<div>推手实得直推收益</div>
							<div class="num">{{transferData.DirectRecommendAwardMoney}}</div>
						</div>
					</div>
		
				</el-card>
			</el-dialog>
		
			<el-dialog title="直播回放" :visible.sync="replayVisible" width="700px">
				<div style="margin-bottom: 15px;">直播结束后大约 10 分钟会生成回放，源视频无评论等内容</div>
				<div v-for="(item,index) in replayList" :key="index">
					<br v-if="item.media_url.indexOf('m3u8') < 0" />
					<a v-if="item.media_url.indexOf('m3u8') < 0" :href="item.media_url" target="_blank" style="font-size: 12px;color: #409EFF;margin-top: 10px;">{{item.media_url}}</a>
				</div>
			</el-dialog>
			<el-dialog title="获取最新直播" :visible.sync="NewbroadVisible" width="600px" @close="CloseNewbroad" :show-close='IshowClose?true:false'
			 :close-on-click-modal='IshowClose?true:false'>
				<div style="color: #000;">请参照在小程序后台，直播间所在的页面，选择需要获取第X页-第Y页的直播间数据</div>
				<div style="color: #999;margin: 20px 0;">例如：想要获取的直播间在小程序后台的第5页至第6页，则在下方输入第5页至第6页；想要获取的直播间在小程序后台的第2页，则在下方输入第2页至第2页</div>
				<div>第<el-input v-model="startPage" :disabled="startPagedis" style="width: 120px;margin-left: 10px;margin-right: 10px;"
					 @input='Onlynumber(startPage,1)'></el-input>页至第<el-input v-model="endPage" :disabled="endPagedis" style="width: 120px;margin-left: 10px;margin-right: 10px;"
					 @input='Onlynumber(endPage,2)'></el-input>页</div>
				<div style="text-align:center;margin-top: 50px;" v-if='operateBtnvisi'>
					<el-button plain @click='CloseNewbroad' style="width: 120px;">关闭</el-button>
					<el-button type="primary" @click="SuregetBtn" style="width:120px" :loading="SuregetLoading">确认获取</el-button>
				</div>
				<div class="progressBars" style="margin-top: 30px;" v-if="barVisible">
					<el-progress :text-inside="true" :stroke-width="24" :percentage="barPercent" status="success"></el-progress>
				</div>
			</el-dialog>
			<!-- 修改分组的弹框 -->
			<el-dialog :title="batchtitle" :visible.sync="eiditeSortVisible" width="800px">
				<label class="label">关键字: </label>
				<el-input v-model="sortSearchKey" placeholder="分组名称" style="width:250px;" clearable></el-input>
				<el-button type="primary" @click="sortsfunFilter" style="margin-left:20px">查询</el-button>
				<!-- 分组名称 -->
				<div class="sorts-names" v-loading="sortNaming">
					<div class="sorttable-top">分组名称</div>
					<div class="sortTable-body" v-for="(item,indexsort) in bathSortDatas" :key="indexsort">
						<el-checkbox :indeterminate="item.isIndeterminate" v-model="item.checkAll" @change="handleCheckAllChange(item)">{{item.GroupName}}</el-checkbox>
					</div>
				</div>
		
				<div style="text-align: center;margin: 30px 0 20px;">
					<el-button @click="eiditeSortVisible=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="sureEditSortfun" style="width:120px" :loading="sortsLoading">确认修改</el-button>
				</div>
			</el-dialog>
			<!-- //批量删除弹窗 -->
			<el-dialog :visible.sync="editbatchdeleteVisi" width="600px" v-loading='deltebatchloading'>
				<div>即将删除{{multipleSelection.length}}个直播间</div>
				<div style="margin-top: 15px;">删除成功后，不可恢复，是否确认继续删除？</div>
				<div style="text-align: center;margin: 30px 0 20px;">
					<el-button @click="editbatchdeleteVisi=false" style="width:120px;margin-right:15px">取消</el-button>
					<el-button type="primary" @click="surebatchDelete" style="width:120px">确认删除</el-button>
				</div>
			</el-dialog>
			<!-- //删除的结果 -->
			<el-dialog :visible.sync="deleteBatchresultVisi" width="600px" :before-close="modelClose">
				<div style="margin-top: 15px;text-align: center;font-size: 16px;">成功删除{{deleteResult.SuccessCount}}个，失败{{deleteResult.FailureCount}}个</div>
				<div style="text-align: center;margin: 30px 0 20px;">
					<el-button type="primary" @click="suredeleteDialog" style="width:120px">确定</el-button>
				</div>
			</el-dialog>
		
		</div>
	</noAuth>
	

</template>

<script>
	import config from '@/config/index'
	import apiList from '@/api/other'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		liveRoomList,
		liveRoomRefreshCache,
		liveRoomisShow,
		liveRoomDelete,
		liveRoomUpdate,
		liveRoomupdate
	} from '@/api/goods'
	import {
		zbRooms
	} from '@/api/tools';

	import {
		LiveroomTradedata,
		livesortDescSave,
		livegroupSetInit,
		livegroupSetSave,
		liveRoomgroupInit,
		liveRoombatchdelete,
		liveupdatePlatform
	} from '@/api/TurnTomySelf';
	
	import noAuth from './components/noAuth.vue';
	
	export default {
		components: {
			buttonPermissions,
			noAuth
		},
		data() {
			return {
				LiveAnchorIdList: [],
				LiveAnchorList: [],
				//3.1.0新增加的
				editbatchdeleteVisi: false,
				deltebatchloading: false,
				deleteBatchresultVisi: false,
				deleteResult: {},
				//结束
				IshowClose: true,
				startPagedis: false,
				endPagedis: false,
				operateBtnvisi: true,
				barVisible: false,
				startPage: null,
				endPage: null,
				SuregetLoading: false,
				transferData: {},
				transferVisible: false,

				replayVisible: false,
				replayList: [],

				searchKey: '',
				starTime: '',
				endTime: '',
				pickerOptions: {
					disabledDate(time) {}
				},
				searchState: null,
				searchStateList: [{
						id: 0,
						value: '预告'
					},
					{
						id: 1,
						value: '直播中'
					},
					{
						id: 2,
						value: '已结束'
					}
				],
				broadData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				headOrderVisible: false,
				ruleForm: {
					headSort: ''
				},
				rules: {
					headSort: {
						required: true,
						message: '请输入首页序号',
						trigger: 'blur'
					}
				},
				saveLoading: false,
				imgUrl: config.IMG_BASE,
				sortData: {},
				//1.5.0获取最新直播
				NewbroadVisible: false,
				CurrentIndex: null,
				pageNum: 0,
				ArrayIndex: [],
				barPercent: 0,
				exportUrl: config.EXPORT_URL,
				//2.0.0
				OrderBy: '',
				IsAsc: '',
				multipleSelection: [],
				isCurrentAll: false,
				checked: false,
				//直播间Id
				batchProductIds: [],
				eiditeSortVisible: false,
				sortSearchKey: '',
				sortNaming: false,
				bathSortDatas: [],
				sortsLoading: false,
				livegrouplist: [],
				groupIds: [],
				batchtitle: '',
				origilist: []
			}
		},
		beforeMount() {
			//主播模式是2
			this.isAgentModel = window.localStorage.getItem('mlmzDistributionModel')
			this.groupIds = this.$route.query.Id || ''
			this.getList();


			// console.log(this.groupIds, '分组Ids')
		},
		created() {
			this.getgroupInit()


		},
		methods: {
			async Editdefault(record) {
				try {
					this.loading = true
					record.IsPlatform = !record.IsPlatform
					let data = {
						RoomId: record.RoomId,
						IsPlatform: record.IsPlatform
					}
					// console.log(data,'888888')
					let result = await liveupdatePlatform(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.getList()
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//3.1.0确认增加的
			modelClose() {
				this.deleteBatchresultVisi = false
				this.getList();
			},
			suredeleteDialog() {
				this.deleteBatchresultVisi = false
				this.getList();
			},
			async surebatchDelete() {
				try {
					this.deltebatchloading = true
					let roomlist = []
					this.multipleSelection.map(record => {
						roomlist.push(record.RoomId)
					})
					let data = {
						RoomIdList: roomlist
					}
					let result = await liveRoombatchdelete(data)
					if (result.IsSuccess) {
						this.editbatchdeleteVisi = false
						this.deleteBatchresultVisi = true
						this.deleteResult = result.Result
					}
					console.log(roomlist, '将要被删除的直播集合')
				} catch (err) {
					this.deltebatchloading = false
				} finally {
					this.deltebatchloading = false
				}
			},
			async getgroupInit() {
				try {
					let result = await liveRoomgroupInit()
					this.livegrouplist = result.Result.GroupSelectList
					this.LiveAnchorList = result.Result.LiveAnchorList
				} catch (err) {

				} finally {

				}
			},
			// 确认修改分组
			async sureEditSortfun() {
				try {

					// this.sortsLoading = true
					let GroupList = []
					this.bathSortDatas.map(item => {
						if (item.checkAll) {
							let obj = {
								Id: item.Id,
								LiveRoomIdList: this.batchProductIds
							}
							GroupList.push(obj)
						}
						//半选的话
						if (item.isIndeterminate) {
							let obj1 = {
								Id: item.Id,
								LiveRoomIdList: item.LiveRoomIdList
							}
							GroupList.push(obj1)
						}
					})

					if (GroupList.length == 0) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '直播间分组不可为空!'
						});
						return
					}
					this.sortsLoading = true
					let data = {
						LiveRoomIdList: this.batchProductIds,
						GroupList: GroupList
					}

					let result = await livegroupSetSave(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getList()
						this.eiditeSortVisible = false;
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.sortsLoading = false
				}

			},
			//选择分组
			handleCheckAllChange(val) {

				this.bathSortDatas.map(item => {
					if (item.Id == val.Id) {
						item.isIndeterminate = false
					}
					return item
				})
			},
			// 查询分组
			sortsfunFilter() {
				let searchkey = this.sortSearchKey
				// console.log(searchkey,'关键字输入')


				if (searchkey) {
					this.bathSortDatas = this.origilist
					let result = this.bathSortDatas.filter(item => {
						return item.GroupName == searchkey
					})
					this.bathSortDatas = result
					// console.log(this.bathSortDatas,'筛选出来的')
				} else {
					this.bathSortDatas = this.origilist
					// console.log(this.origilist,'原始数据')
				}


			},
			// 商品分组初始化
			async batchGroupInt() {
				try {
					this.sortNaming = true
					let data = {
						IdList: this.batchProductIds,
						// KeyWord: this.sortSearchKey
					}
					let result = await livegroupSetInit(data)
					this.bathSortDatas = []
					result.Result.map(item => {
						let obj = {
							GroupName: item.GroupName,
							Id: item.Id,
							LiveRoomIdList: item.LiveRoomIdList,
							Selected: item.Selected
						}
						if (item.Selected == 2) {
							// 所有商品均选中
							obj.isIndeterminate = false,
								obj.checkAll = true
						} else if (item.Selected == 1) {
							// 部分商品选中
							obj.isIndeterminate = true,
								obj.checkAll = false
						} else if (item.Selected == 0) {
							// 所有商品都不选中
							obj.isIndeterminate = false,
								obj.checkAll = false
						}
						this.bathSortDatas.push(obj)

						// console.log(this.bathSortDatas,'分组数据23232')
						this.origilist.push(obj)
					})

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.sortNaming = false
				}

			},
			// 设置分组
			sortsBatchs(val) {
				//单选
				this.batchProductIds = []
				if (val.RoomId) {
					this.batchProductIds.push(val.Id)
					this.batchtitle = '修改直播间分组'
				}
				//多选
				else {
					if (this.multipleSelection.length < 1) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请选择直播间!'
						});
						return
					}
					this.batchtitle = '批量修改直播间分组'
					//直播Id
					this.multipleSelection.map(record => {
						this.batchProductIds.push(record.Id)
					})
				}
				// console.log(this.batchProductIds,'选中的直播间id')

				//单个和批量区分

				//渲染分组
				this.batchGroupInt()
				this.eiditeSortVisible = true
			},
			tabCheckedChange() {
				this.$refs['multipleTable'].toggleAllSelection();
			},
			//批量修改分组
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.isCurrentAll = val.length == this.broadData.length ? true : false
				if (this.isCurrentAll) {
					this.checked = true
				} else {
					this.checked = false
				}
			},
			// 序号排序
			sortChange(column, prop, order) {
				if (column.order == "ascending") {
					this.IsAsc = true
					this.OrderBy = 'SortDesc';
				} else {
					this.IsAsc = false
					this.OrderBy = 'SortDesc DESC'
				}
				this.currentPage = 1;
				this.getList();
			},
			//修改序号
			OnlyNumber1(obj, index) {
				obj = obj.replace(/[^\.\d]/g, '');
				obj = obj.replace('.', '');
				this.broadData[index].SortDesc = obj
			},
			//保存序号
			async SaveNumber(record, index) {
				try {
					if (record.SortDesc > 99999) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '序号值请设置在0~99999之间，整数!'
						});
						return
					}
					this.loading = true
					let data = {
						Id: record.Id,
						SortDesc: record.SortDesc
					}
					let result = await livesortDescSave(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.broadData[index].Numbervisible = false
						this.getList()

					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			async changedata(row) {
				try {
					let data = {
						RoomId: row.RoomId,
						StartTime: row.StartEndtime[0],
						EndTime: row.StartEndtime[1]
					}
					let starts = new Date(row.StartEndtime[0]).getTime()
					let ends = new Date(row.StartEndtime[1]).getTime()
					if (starts > ends || starts == ends) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '开始时间需小于结束时间!'
						});
						return
					}
					let result = await liveRoomupdate(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.getList();
					}

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {

				}

			},
			// 修改直播状态
			async stateFun(record, type) {
				try {
					let data = {
						RoomId: record.RoomId,
						State: type
					}
					let result = await liveRoomUpdate(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.getList();
				}
			},
			//交易转化
			async gettransData(record) {
				this.transferVisible = true
				this.loading = true
				try {
					let data = {
						RoomId: record.RoomId
					}
					let result = await LiveroomTradedata(data)
					this.transferData = result.Result

				} catch (err) {

				} finally {
					this.loading = false
				}

			},
			// 删除直播间
			deleteChose(record) {
				//单个删除
				if (record.RoomId) {
					this.$confirm('删除成功后，不可恢复，是否确认确认继续删除?', '提示', {
						confirmButtonText: '继续删除',
						cancelButtonText: '关闭',
						type: 'warning'
					}).then(async () => {
						try {
							let result = await liveRoomDelete({
								RoomId: record.RoomId
							})
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '删除成功!'
								});
							}

						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {

						}


					}).catch(() => {
						this.$message({
							showClose: true,
							type: 'info',
							message: '已取消删除'
						});
					}).finally(() => {
						this.currentPage = 1;
						setTimeout(() => {
							this.getList();
						}, 500)

					})
				} else {
					if (!this.multipleSelection.length) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请先勾选直播间!'
						});
						return
					}

					this.editbatchdeleteVisi = true


				}


			},
			// 获取列表
			async getList() {
				try {
					this.loading = true
					let data = {
						LiveAnchorIdList: this.LiveAnchorIdList.includes(-1) ? '' : this.LiveAnchorIdList,
						KeyWords: this.searchKey, // 搜索关键字
						SearchStartDate: this.starTime,
						SearchEndDate: this.endTime,
						GroupIdList: this.groupIds.includes(-1) ? '' : this.groupIds,
						State: this.searchState,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc
					}
					// console.log(data.SearchStartDate,data.SearchEndDate)
					let result = await liveRoomList(data);
					this.Total = result.Result.Total;
					this.broadData = result.Result.Results || [];
					this.broadData.map(item => {
						item.StartEndtime = [item.StartTime, item.EndTime]
						// console.log(item.StartTime, item.EndTime)
						return item
					})
				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},
			keyChange() {
				console.log(this.groupIds, 'ksjdks')
				// this.currentPage = 1
				// this.getList()
			},
			goodsFilter() {
				this.currentPage = 1
				this.getList()
			},
			//点击确认获取
			SuregetBtn() {
				if (!this.startPage || this.startPage == 0) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入开始页!'
					});
					return
				}
				if (!this.endPage || this.endPage == 0) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请输入结束页!'
					});
					return
				}
				if (Number(this.startPage) > Number(this.endPage)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请从前往后输入页码!'
					});
					return
				}
				if (Number(this.endPage) - Number(this.startPage) > 9) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '页码区间最多10页!'
					});
					return
				}
				//间隔的页数及页码数
				this.ArrayIndex = []
				for (var i = this.startPage; i <= this.endPage; i++) {
					this.ArrayIndex.push(Number(i))
				}
				this.startPagedis = true
				this.endPagedis = true
				this.operateBtnvisi = false;
				this.IshowClose = false
				this.barVisible = true
				this.CurrentIndex = 1
				this.pageNum = Number(this.startPage)
				this.getSurepage()
			},
			async getSurepage() {
				try {
					if (this.CurrentIndex > this.ArrayIndex.length) {
						return;
					}
					let data = {
						Skip: (this.pageNum - 1) * 10, // 略过数据
						Take: 10
					}
					let result = await apiList.liveRoomRefreshPage(data)
					if (result.IsSuccess) {
						let step = parseInt(100 / this.ArrayIndex.length)
						if (this.CurrentIndex == this.ArrayIndex.length) {
							this.barPercent = 100
							setTimeout(() => {
								this.NewbroadVisible = false;
								this.getList()
							}, 1000)
						} else {
							this.barPercent = step * this.CurrentIndex
						}
						this.CurrentIndex++;
						this.pageNum++;
					}
					this.getSurepage();
				} catch (err) {
					// this.$message({
					// 	showClose: true,
					// 	type: 'error',
					// 	message: err
					// });
					this.IshowClose = true
					// console.log(err, '出现的错误')
				} finally {

				}

			},
			//只能输入整数
			Onlynumber(obj, index) {
				obj = obj.replace(/[^\.\d]/g, '');
				obj = obj.replace('.', '');
				console.log(obj, '输入的数字')
				//起始页
				if (index == 1) {
					this.startPage = obj

					console.log(this.startPage, '开始的页码')
				} else {
					this.endPage = obj
				}
			},
			// 获取最新直播
			getNewBroad() {
				this.NewbroadVisible = true
				this.startPage = null
				this.endPage = null
				this.startPagedis = false
				this.endPagedis = false
				this.operateBtnvisi = true;
				this.barVisible = false
				this.barPercent = 0

			},
			//关闭弹窗
			CloseNewbroad() {
				// this.startPage = null
				// this.endPage = null
				this.NewbroadVisible = false
			},
			// async getNewBroad() {
			// 	try {
			// 		let result = await liveRoomRefreshCache()
			// 		if (result.IsSuccess) {
			// 			this.$message({
			// 				showClose: true,
			// 				type: 'success',
			// 				message: '操作成功!'
			// 			});
			// 		}
			// 	} catch (e) {

			// 		//TODO handle the exception
			// 		console.log(e)
			// 	} finally {
			// 		this.getList()
			// 	}

			// },
			rowKeys(row) {
				return row.Id
			},
			// 是否展示在首页
			async isShowHead(record) {
				try {
					let data = {
						RoomId: record.RoomId,
						IsShow: !record.IsShow,
						SortDesc: record.SortDesc
					}
					let result = await liveRoomisShow(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				} catch (e) {
					//TODO handle the exception
				} finally {
					this.getList()
				}

			},
			// 修改序号
			sortsEdit(record) {
				this.sortData = record;
				this.ruleForm.headSort = record.SortDesc
				this.headOrderVisible = true
			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},
			CanClose() {
				this.ruleForm.headSort = ''
			},
			codeChangefun() {
				this.ruleForm.headSort = this.ruleForm.headSort.replace(/[\W]/g, '');
			},
			// 保存修改序号
			sureSends(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveLoading = true

							let data = {
								RoomId: this.sortData.RoomId,
								IsShow: this.sortData.IsShow,
								SortDesc: this.ruleForm.headSort
							}
							let result = await liveRoomisShow(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.getList();
								this.headOrderVisible = false
							}

						} catch (e) {
							//TODO handle the exception
						} finally {
							this.saveLoading = false
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},

			//回放相关
			showReplay(record) {
				this.replayVisible = true;
				this.getReplayData(record);
			},
			async getReplayData(record) {
				try {
					const res = await zbRooms({
						start: 0,
						limit: 100,
						action: "get_replay", // 获取回放
						room_id: record.RoomId, // 直播间id
					})

					this.replayList = res.Result.live_replay.reverse();
				} catch (e) {

				}

			}

		}
	}
</script>

<style lang="less">
	.broadcastManage {
		width: 100%;
		background: #fff;
		padding: 15px;

		.el-card__body {
			padding-top: 0px !important;
		}

		.pointers {
			cursor: pointer;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.transfercontent {
			// justify-content: ;
			flex-wrap: wrap;

			.item_content {
				width: 25%;
				margin-top: 45px;

				.num {
					margin-top: 10px;
				}
			}
		}

		.sorts-names {
			max-height: 400px;
			overflow: auto;
			margin-top: 20px;
		}

		.sorttable-top {
			width: 100%;
			background: #f8f8f9;
			padding: 15px;
		}

		.sortTable-body {
			width: 100%;
			padding: 15px;
		}
	}
</style>
